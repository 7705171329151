import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import { gql } from "graphql-request";
import { useFilminfo } from "src/lib/client/useFilminfo";
import styled from "src/lib/styles/css";
import theme from "src/components/themes/DefaultTheme";
import useIsConsentReady from "src/lib/client/useIsConsentReady";
import EmbedConsentPlaceholder from "src/components/richtext/EmbedConsentPlaceholder";


//#region [Component]
export default function Twentythree({ value }: TwentythreeProps) {
    const { fiLoading, fiData } = useFilminfo(QUERY, { clipId: value?.videoId }, { active: !!value?.videoId });
    const { isConsentReady } = useIsConsentReady();

    if (!isConsentReady) {
        return <SConsentPlaceholder />;
    }

    if (!value?.videoId) {
        return null;
    }

    if (fiLoading) {
        return <div><LoadingIndicator position="InlineCenter" /></div>
    }

    if (!fiLoading && !fiData?.movieQuery?.getMovieClip?.embedCode) {
        return null;
    }

    return <SFigure>
        <STwentythreeContainer dangerouslySetInnerHTML={{ __html: fiData!.movieQuery!.getMovieClip!.embedCode! }} />
        {value?.clipText && <SFigcaption>{value?.clipText}</SFigcaption>}
    </SFigure>

}

const QUERY = gql`query ($clipId: Int, $playerId: Int) {
	movieQuery {
		getMovieClip(clipId: $clipId, playerId: $playerId, autoplay: false) {
			clipTitle
			id
			embedCode
		}
	}
}`;
//#endregion

//#region [Styles]
const STwentythreeContainer = styled.div`
	aspect-ratio: 16 / 9;
`;

const SFigure = styled.figure`
	margin: 1em auto;

`;

const SFigcaption = styled.figcaption`
	font-size: ${theme.fonts.bodytextS.size};
`;

const SConsentPlaceholder = styled(EmbedConsentPlaceholder)`
    aspect-ratio: 16 / 9;
    margin: 1em auto;
`;
//#endregion

//#region [Props]
type TwentythreeProps = {
    value: {
        videoId: number;
        clipText?: string;
    }
};
//#endregion